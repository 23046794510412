<template>
  <table>
    <tbody>
      <tr>
        <td
          :colspan="
            datasource.children && datasource.children.length
              ? datasource.children.length * 2
              : null
          "
        >
          <div class="node" :id="datasource.id">
            <!-- @click.stop="handleClick(datasource)" -->
            <slot :node-data="datasource">
              <div>
                <!-- 顶部标题卡片部分 -->
                <div class="title" :style="{ background: titleBackground }">
                  <!-- 卡片顶部可点击区域 -->
                  <a
                    :href="`#/commonObjects/detail/${datasource.pathid}/DETAIL`"
                    style="color: #fff"
                    class="link-style"
                    @click="clickLinkCheck(datasource)"
                  >{{ datasource.name }}</a>

                  <!-- 职务 -->
                  <div>{{ datasource.zhiwu }}</div>

                  <!-- 编辑按钮 -->
                  <div @click="editClickEvent(datasource)" v-if="datasource.isShowEditBtn">
                    <svg aria-hidden="true" class="icon edit_btn">
                      <use href="#icon-pen" />
                    </svg>
                  </div>
                </div>

                <!-- 卡片体部分 -->
                <div class="chart-body">
                  <!-- 图表 -->
                  <v-chart
                    v-if="datasource.vEchartsObj"
                    :options="options"
                    style="width: 100%; height: 100%"
                    class="echarts"
                  />
                </div>
              </div>
            </slot>
          </div>
        </td>
      </tr>
      <template v-if="datasource.children && datasource.children.length">
        <tr class="lines">
          <td :colspan="datasource.children.length * 2">
            <div class="downLine"></div>
          </td>
        </tr>
        <tr class="lines">
          <td class="rightLine"></td>
          <template v-for="n in datasource.children.length - 1">
            <td class="leftLine topLine"></td>
            <td class="rightLine topLine"></td>
          </template>
          <td class="leftLine"></td>
        </tr>
        <tr class="nodes">
          <td colspan="2" v-for="child in datasource.children" :key="child.id">
            <node :datasource="child" :handle-click="handleClick">
              <!-- <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"/>
              </template>-->
            </node>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { getPermissionById } from "./api";

export default {
  name: "node",
  props: {
    datasource: {
      type: Object,
      default: "",
    },
    handleClick: Function,
    // 是否显示编辑按钮
    isShowEditBtn:{
      type:Boolean,
      default:false
    }
  },
  data() {
    // var datamax = [20, 20, 20, 20, 20]; //参数的最大值
    // var dataname = ["购买风格", "角色", "拜访次数", "对我司态度", "成熟度"]; //每个属性的名称
    // var dataVaule = [11, 16, 16, 15, 12]; //每个属性的值
    // var tootipValue = ["审批者", "产品", "握手", "十分肯定", "10"]; //tootip 的 value值

    var datamax = [20, 20, 20, 20, 20]; //参数的最大值
    // var tootipDataname = [
    //   "角色",
    //   "购买风格",
    //   "对我司态度",
    //   "成熟度",
    //   "拜访次数",
    // ]; //tootip 的 key 值(写死)
    var tootipDataname = [
      "拜访次数",
      this.$i18n.t("vue_label_commonobjects_detail_relationship"),
      "购买风格",
      "角色",
      this.$i18n.t("vue_label_commonobjects_detail_visitstate"),
    ]; //tootip 的 key 值(写死)

    // var dataname = ["拜访次数", "对我司态度", "购买风格", "角色", "成熟度"]; //每个属性的key名称
    var dataname = this.datasource.vEchartsObj
      ? this.datasource.vEchartsObj.tootipValue
      : []; //每个图表的显示的title值（和tootip 的 value值一样）
    var count = this.datasource.vEchartsObj
      ? this.datasource.vEchartsObj.count
      : ""; //总分
    var dataVaule = this.datasource.vEchartsObj
      ? this.datasource.vEchartsObj.dataValue
      : []; //每个属性的值
    dataVaule = dataVaule.map((item) =>
      item === null ? this.$i18n.t("label.structureChart.notFiled") : item
    );

    var tootipValue = this.datasource.vEchartsObj
      ? this.datasource.vEchartsObj.tootipValue
      : []; //tootip 的 value值

    tootipValue = tootipValue.map((item) =>
      item === null ? this.$i18n.t("label.structureChart.notFiled") : item
    );

    // 图表的一圈文字
    var indicator = [];
    for (var i = 0; i < dataname.length; i++) {
      indicator.push({
        name:
          dataname[i] === null
            ? this.$i18n.t("label.structureChart.notFiled")
            : dataname[i],
        max: datamax[i],
      });
    }
    return {
      // 配置
      options: {
        tooltip: {
          show: false,
          trigger: "item",
        },
        radar: {
          center: ["50%", "50%"],
          radius: "30",
          startAngle: 90,
          splitNumber: 5,
          splitArea: {
            areaStyle: {
              color: "#fff",
              shadowColor: "rgba(0, 0, 0, 0.3)",
              shadowBlur: 4,
            },
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#ccc",
              width: 1,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#0091FF",
              width: 1,
            },
          },
          nameGap: 10,
          name: {
            formatter: "{value}",
            textStyle: {
              color: "#656565",
              fontSize: 10,
              lineHeight: 0,
            },
          },
          indicator: indicator,
        },

        series: [
          {
            name: "总分" + ": 20", //没有用了，使用format格式化了
            type: "radar",
            symbol: "none",
            symbolSize: 6,
            areaStyle: {
              normal: {
                color: "#80B2FF",
              },
            },
            itemStyle: {
              color: "#80B2FF",
            },
            lineStyle: {
              normal: {
                color: "#80B2FF",
                width: 2,
              },
            },
            data: [
              {
                value: dataVaule, //当前图表的value值
                data: tootipValue, // tootip 的文字 value 值
                dataname: tootipDataname, //tootip 的 key 值
              },
            ],
            tooltip: {
              show: true,
              trigger: "item",
              formatter: (e) => {
                let html = "";
                for (let i = 0; i < dataname.length; i++) {
                  html += `${e.data.dataname[i]} : ${e.data.data[i]}<br/>`;
                  // html += `${e.data.dataname[0]} : ${e.data.data[3]}<br/>
                  //   ${e.data.dataname[1]} : ${e.data.data[2]}<br/>
                  //   ${e.data.dataname[2]} : ${e.data.data[1]}<br/>
                  //   ${e.data.dataname[3]} : ${e.data.data[4]}<br/>
                  //   ${e.data.dataname[4]} : ${e.data.data[0]}<br/>
                  //   `;
                }
                return `总分 : ${count}<br/>${html}`;
              },
              //文字左对齐
              textStyle: {
                align: "left",
              },
            },
          },
        ],
      },
      /**
       *  当前模块的重新组装后的对象，
       *   在使用其中的link 属性，
       *  再该模块中根据 datasource2有无 link 属性判断是否可跳转进客户详情页
       *   否则给出提示
       */
      datasource2: {},
    };
  },
  methods: {
    // 是否可跳转到客户详情页检查
    async clickLinkCheck(currentClickObj) {
      // 获取记录查看权限，无权限时给提示，可返回上一级
      let { data } = await getPermissionById({
        id: currentClickObj.pathid,
      });
      if (data && data.isQuery === true) {
        this.$router.push(
          `/commonObjects/detail/${currentClickObj.pathid}/DETAIL`
        );
      } else {
        this.$message.warning("您没有权限查看,请联系管理员!");
      }
    },
    // 每个卡片的编辑按钮点击
    editClickEvent(datasource) {
      this.$bus.$emit("editClickEvent", datasource);
    },
  },
  computed: {
    // 标题的背景颜色
    titleBackground() {
      let tmpHierarchyType = this.datasource.hierarchies;
      // 未分配: ""
      // 管理层: "manage"
      // 战略层: "strategy"
      // 执行层: "execute"
      switch (tmpHierarchyType) {
        case "管理层":
          return "#FFA973";
          break;
        case "战略层":
          return "#4BDA7B";
          break;
        case "执行层":
          return "#5497F4";
          break;
        default:
          return "#ccc";
          break;
      }
    },
  },
};
</script>

<style scoped>
.content {
  height: 160px;
}
.chart-body {
  height: 160px;
  border: 1px solid #dddbda;
  border-top: none;
  background-color: #fff;
}
.link-style {
  text-decoration: underline;
}

.title {
  position: relative;
}
.edit_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
